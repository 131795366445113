<template>
  <div class="box">
    <el-row class="but">
      <el-button @click="but">←返回</el-button>
    </el-row>
    <el-card class="box-card">
      <div class="statistics">
        <el-select
          v-model="classroom"
          placeholder="选择教室"
          size="mini"
          @change="changeClassroom"
          class="select"
        >
          <el-option
            v-for="item in classroomData"
            :key="item.id"
            :label="item.className"
            :value="item.id"
          ></el-option>
        </el-select>
        <el-select
          v-model="activity"
          placeholder="选择活动"
          @change="changeActivity"
          size="mini"
          class="select"
        >
          <el-option
            v-for="item in activityData"
            :key="item.id"
            :label="item.title"
            :value="item.id"
            :disabled="item.disabled"
          ></el-option>
        </el-select>
        <el-select
          v-model="topic"
          placeholder="选择题目"
          size="mini"
          @change="changeTopicData"
          class="select"
        >
          <el-option
            v-for="item in topicData"
            :key="item.caseId"
            :label="item.stemName"
            :value="item.caseId"
            :disabled="item.disabled"
          ></el-option>
        </el-select>
        <div class="box-right">
          <el-card>
            总人数:
            <div class="num">50</div>
          </el-card>
          <el-card>
            参与人数:
            <div class="num">10</div>
          </el-card>
          <el-card>
            总题目数:
            <div class="num">8</div>
          </el-card>
        </div>
      </div>
      <div class="box-content">
        <div class="box-left">
          <div v-html="word" style="height: 100%; width: 100%"></div>
        </div>
        <el-main class="box-content">
          <el-card class="content" v-for="(item, index) in chartData" :key="index">
            <span>{{ item.title }}</span>
            <i class="el-icon-circle-close" @click="close(item.id, index)"></i>
            <div class="content-box">
              <div class="content-left">
                <p>{{ item.tagname }}</p>
                <div style="width: 100%; height: 90%">
                  <div :id="item.id" style="width: 100%; height: 100%"></div>
                </div>
              </div>
              <div class="content-right">
                <p>答案对照</p>
                <div>
                  <el-select
                    v-model="item.professorValue"
                    @change="
                      (val) => {
                        changeProfessorValue(val, index);
                      }
                    "
                    placeholder="参考答案"
                    size="mini"
                    style="width: 100px"
                    class="select"
                  >
                    <el-option
                      v-for="item in item.professor"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                      :disabled="item.disabled"
                    ></el-option>
                  </el-select>
                  <i>
                    <el-input
                      v-model="item.professorAnswer"
                      placeholder="内容展示"
                      size="mini"
                      style="width: 55%"
                    ></el-input>
                  </i>
                </div>

                <div>
                  <el-select
                    v-model="item.teacherValue"
                    @change="
                      (val) => {
                        changeTeacherValue(val, index);
                      }
                    "
                    placeholder="参与者"
                    size="mini"
                    style="width: 100px"
                    class="select"
                  >
                    <el-option
                      v-for="item in item.teacher"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                      :disabled="item.disabled"
                    ></el-option>
                  </el-select>
                  <i>
                    <el-input
                      v-model="item.teacherAnswer"
                      placeholder="内容展示"
                      size="mini"
                      style="width: 55%"
                    ></el-input>
                  </i>
                </div>
              </div>
            </div>
          </el-card>
        </el-main>

        <div class="box-right">
          <el-tree
            :data="Tree"
            show-checkbox
            node-key="id"
            :default-expand-all="true"
            :props="defaultProps"
            @check="onChangeTree"
            ref="tree"
          ></el-tree>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import * as echarts from "echarts";
import { title } from "process";
import {
  classList,
  activeList,
  specialTopicByStudent,
  activeDetails,
  resourceDetail,
  templateDetail,
} from "../../api/api";
export default {
  data () {
    return {
      classroomData: [],
      activityData: [],
      topicData: [],
      classroom: "", //教室
      activity: "", //活动
      topic: "", //题目
      templateId: null, //标签模板
      //图表数据
      chartData: [],
      //树状
      Tree: [
        {
          id: 1,
          label: "客观属性指标",
          children: [
            {
              id: 4,
              title: "客观属性指标",
              label: "年份/时间",
              xAxis: ["2012", "2013", "2014", "2015", "2016", "2017", "2018"],
              series: [5, 10, 60, 80, 70, 110, 20],
              professor: [
                {
                  value: "2012",
                  label: "沈教授",
                },
                {
                  value: "2013",
                  label: "王教授",
                },
                {
                  value: "2014",
                  label: "李教授",
                },
                {
                  value: "2015",
                  label: "钟教授",
                },
                {
                  value: "2016",
                  label: "占教授",
                },
              ],
              teacher: [
                {
                  value: "2012",
                  label: "沈老师",
                },
                {
                  value: "2013",
                  label: "王老师",
                },
                {
                  value: "2014",
                  label: "李老师",
                },
                {
                  value: "2017",
                  label: "钟老师",
                },
                {
                  value: "2016",
                  label: "占老师",
                },
              ],
            },
            {
              id: 11,
              title: "客观属性指标",
              label: "题型",
              xAxis: ["多选", "单选", "数值", "文本"],
              series: [120, 200, 150, 80],
              professor: [
                {
                  value: "多选",
                  label: "沈教授",
                },
                {
                  value: "单选",
                  label: "王教授",
                },
                {
                  value: "数值",
                  label: "李教授",
                },
                {
                  value: "文本",
                  label: "钟教授",
                },
              ],
              teacher: [
                {
                  value: "单选",
                  label: "沈老师",
                },
                {
                  value: "多选",
                  label: "王老师",
                },
                {
                  value: "数值",
                  label: "李老师",
                },
                {
                  value: "文本",
                  label: "占老师",
                },
              ],
            },
          ],
        },

        {
          id: 2,
          label: "测量量化指标",
          children: [
            {
              id: 5,
              title: "测量量化指标",
              label: "难度",

              xAxis: ["1", "2", "3", "4", "5", "6", "7"],
              series: [120, 200, 150, 80, 70, 110, 400],
            },
            {
              id: 6,
              title: "测量量化指标",
              label: "区分度",
              xAxis: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
              series: [1, 10, 150, 80, 70, 110, 800],
            },
          ],
        },

        {
          id: 3,
          label: "测量维度指标",

          children: [
            {
              id: 7,
              label: "内容维度",
              children: [
                {
                  id: 9,
                  title: "内容维度",
                  label: "数与式",
                  xAxis: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
                  series: [1, 10, 150, 80, 70, 110, 800],
                },
                {
                  id: 10,
                  title: "内容维度",
                  label: "方程与不等式",
                  xAxis: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
                  series: [1, 10, 150, 80, 70, 110, 800],
                },
                {
                  id: 20,
                  label: "我是三级",
                  children: [
                    {
                      id: 40,
                      title: "我是三级",
                      label: "三级里面的方程与不等式",
                      xAxis: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
                      series: [1, 10, 150, 80, 70, 110, 800],
                    },
                  ],
                },
              ],
            },

            {
              id: 8,
              label: "素养维度",
              children: [
                {
                  id: 12,
                  title: "素养维度",
                  label: "素养维度",
                  xAxis: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
                  series: [1, 10, 150, 80, 70, 110, 800],
                },
              ],
            },
          ],
        },
      ],
      defaultProps: {
        children: "children",
        label: "label",
      },
      defaultPropssss: {
        children: "children",
        label: "name",
      },

      word: null,
      IsAllTree: false,
      ceshi: [],
    };
  },

  methods: {
    //初始化
    init () {
      classList({}).then((res) => {
        this.classroomData = res.data.list;
      });
      templateDetail({ id: 174 }).then((res) => {
        this.ceshi = res.data.label;
        this.labelTagTree(this.ceshi);
      });
    },
    //选择教室
    changeClassroom (val) {
      this.activity = "";
      this.topic = "";
      this.activityData = [];
      this.topicData = [];
      this.templateId = null;
      activeList({
        classId: val,
      }).then((res) => {
        this.activityData = res.data.list;
      });
    },
    //选择活动
    changeActivity (val) {
      this.topic = "";
      this.topicData = [];
      var params = { id: val };
      activeDetails(params).then((res) => {
        this.topicData = res.data.stems;
        this.templateId = res.data.templateId;
      });
    },
    //选择题目
    changeTopicData (val) {
      resourceDetail({ id: val }).then((res) => {
        this.word =
          '<iframe src="https://view.officeapps.live.com/op/view.aspx?src=' +
          res.data.url +
          '" style="width:100%;height:100%"></iframe>';
      });
      templateDetail({ id: this.templateId }).then((res) => {
        this.labelTagTree(res.data.label);
      });
    },
    //处理标签数据结构
    labelTagTree (val) {
      val.map((item) => {
        if (item.children.length > 0) {
          this.labelTagTree(item.children);
        } else {
          if (item.tag.length > 0) {
            item.tag.map((tag) => {
              item.children.push(tag);
            });
          }
        }
      });
    },
    //关闭
    close (id, index) {
      this.$refs.tree.setChecked(id);
      this.chartData.splice(index, 1);
    },
    //点击树状图
    onChangeTree (val, a) {
      if (val.children) {
        this.IsAllTree = false;

        this.MasterNodeTree(val);
        if (this.IsAllTree) {
        } else {
          this.IsAllNodeTree(val);
        }
      } else {
        //渲染图表
        var is = true;
        if (this.chartData.length > 0) {
          this.chartData.map((item, index) => {
            if (item.id == val.id) {
              is = false;
              this.chartData.splice(index, 1);
            }
          });
        }
        if (is) {
          this.chartData.push({
            id: val.id,
            title: val.title,
            tagname: val.label,
            professor: val.professor, //教授
            teacher: val.teacher, //老师
            xAxis: val.xAxis,
            series: val.series,
            professorAnswer: "",
            teacherAnswer: "",
            professorValue: "",
            teacherValue: "",
          });
          setTimeout(() => {
            this.drawColumnChart(val.id, val.xAxis, val.series);
          }, 200);
        }
      }
    },
    //图表
    drawColumnChart (val, xAxis, series) {
      var chartDom = document.getElementById(val);
      var myChart = echarts.init(chartDom);
      var option;
      option = {
        xAxis: {
          type: "category",
          data: xAxis,
        },
        yAxis: {
          type: "value",
        },
        grid: {
          left: "3%",
          right: "10%",
          bottom: "1%",
          top: "8%",
          containLabel: true,
        },
        series: [
          {
            data: series,
            type: "bar",
          },
        ],
        tooltip: {
          shou: true,
        },
      };
      option && myChart.setOption(option);
    },
    //点击树状图的主节点
    MasterNodeTree (val) {
      if (val.children) {
        this.MasterNodeTree(val.children);
      } else {
        val.map((val) => {
          if (val.children) {
            this.MasterNodeTree(val.children);
          } else {
            //不是维度
            var is = true;
            this.chartData.map((item, index) => {
              if (item.id == val.id) {
                is = false;
              }
            });
            if (is) {
              this.IsAllTree = true;
              this.chartData.push({
                id: val.id,
                title: val.title,
                tagname: val.label,
                professor: val.professor, //教授
                teacher: val.teacher, //老师
                xAxis: val.xAxis,
                series: val.series,
                professorAnswer: "",
                teacherAnswer: "",
                professorValue: "",
                teacherValue: "",
              });
              setTimeout(() => {
                this.drawColumnChart(val.id, val.xAxis, val.series);
              }, 200);
            }
          }
        });
      }
    },
    //是否关闭所有树状图的节点
    IsAllNodeTree (val) {
      if (val.children) {
        this.IsAllNodeTree(val.children);
      } else {
        val.map((val) => {
          if (val.children) {
            this.IsAllNodeTree(val.children);
          } else {
            this.chartData.map((item, index) => {
              if (item.id == val.id) {
                this.chartData.splice(index, 1);
              }
            });
          }
        });
      }
      //this.chartData
    },
    //获取教授参考答案
    changeProfessorValue (val, index) {
      this.chartData[index].professorAnswer = val;
      // "教授的id是" + val + "所在的在第几个图表" + index;
    },
    //获取参与者答案
    changeTeacherValue (val, index) {
      this.chartData[index].teacherAnswer = val;
      // "老师的id是" + val + "所在的在第几个图表" + index;
    },
    //返回
    but () {
      this.$router.back(-1);
    },
  },
  mounted () { },
  created () {
    this.init();
  },
};
</script>

<style lang='less' scoped>
@import '../../style/tagChart/index.less';
@import '../../style/mouse.less';
</style>